import React from "react";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "../styles/blog.css";

const CasestudiesPage = (props) => {
  const { strapiCasestudies: currentCasestudy } = props.data;
  const createdImageOne = getImage(props.data.strapiCasestudies.image);
  const createdImageTwo = getImage(currentCasestudy.casestudy_author.image);

  return (
    <Layout>
      <section className="section singlepost p-4">
        <div className="container content py-2">
          <div className="row justify-content-between mb-4">
            <div className="col-lg-9 px-0">
              <div className="single-post-cover mb-5">
                <GatsbyImage
                  image={createdImageOne}
                  alt={`${props.data.strapiCasestudies.title} casestudies banner image`}
                />
              </div>
              <h1 className="single-title is-size-1 has-text-weight-bold is-bold-light">
                {props.data.strapiCasestudies.title}
              </h1>
              <ReactMarkdown
                className="single-description"
                source={props.data.strapiCasestudies.content}
              />
            </div>
            <div className="col-lg-3 rightsidebar">
              <h5 className="single-author-title my-2 mx-4">Author</h5>
              <ul className="single-author mx-4 pt-2">
                <li>
                  <GatsbyImage
                    image={createdImageTwo}
                    alt={`${currentCasestudy.casestudy_author.name} casestudies author image`}
                  />
                </li>
                <li>
                  <h5>{currentCasestudy.casestudy_author.name}</h5>
                  <p>{currentCasestudy.casestudy_author.role}</p>
                </li>
              </ul>
              <h4 className="single-author-read  mx-4">
                {`${currentCasestudy.published_at}.  ${currentCasestudy.readTime}`}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query GetCasestudies($casestudy_slug: String) {
    strapiCasestudies(slug: { eq: $casestudy_slug }) {
      content
      description
      slug
      title
      readTime
      published_at(formatString: "MMMM DD, YYYY")
      casestudy_author {
        slug
        role
        name
        image {
          childImageSharp {
            gatsbyImageData(quality: 10, width: 100, placeholder: NONE)
          }
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(quality: 20, width: 850, placeholder: NONE)
        }
      }
    }
  }
`;
export default CasestudiesPage;
